label {
    display: block;
    font-weight: 500;
}

.p-field-checkbox, .p-field-radiobutton {
    display: flex;

    label {
        font-weight: 400;
        margin-left: 13px;
    }

    &.disabled {
        pointer-events: none;
        accent-color: var(--gray-400);
    }

    &.readonly {
        pointer-events: none;
        opacity: .5;
    }
}

input, .p-dropdown, p-inputwrapper {
    border: 1px solid $color-border;
    border-radius: 2px;
    width: 100%;

    &:focus, &:hover {
        border: 1px solid $color-border-active !important;
        outline: none !important;
    }
}

.p-input-icon-left {
    width: 100%;
}

.p-calendar {
    border: 1px solid $color-border;
    width: 100%;

    .p-inputtext {
        border: none;

        &:hover, &:focus {
            border: none !important;
        }
    }

    .p-button {
        background: var(--color-white);
        border: none;

        &:hover, &:focus {
            background: var(--color-white) !important;
        }

        &:active {
            background: var(--color-light) !important;
        }

        span {
            color: $color-black;
        }
    }
}

.p-inputnumber, p-inputNumber {
    width: 100%;
}

.p-dropdown-label {
    font-family: $font-lexend !important;
}

p-multiselect, .p-multiselect {
    width: 100%;
}
