.skew-background {
    position: absolute;
    height: 500px;
    left: 0;
    right: 0;
    z-index: -2;

    &.skew-header {
        top: -5rem;
        height: 660px;
        @media (max-width: 600px) {
            height: 750px;
        }

        &:before {
            background: var(--color-primary);

            @media (max-width: 600px) {
                transform: skewy(333deg);
            }
        }
    }

    &.skew-home {
        top: 500px;
        height: 250px;

        &:before {
            background: $color-white;
        }
    }

    &.skew-1 {
        top: 17rem;
    }

    &.skew-2 {
        top: 80rem;
    }

    &.skew-3 {
        top: 20rem;
    }

    &:before {
        content: "";
        background-color: transparentize($color-border, 0.8);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: skewy(-6deg);
        transform-origin: 50% 0;
        outline: 1px solid transparent;
        backface-visibility: hidden;
    }
}

.oval {
    position: absolute;
    z-index: -1;

    &.oval--1 {
        top: 391px;
        right: 41px;
    }

    &.oval--2 {
        top: 1008px;
        right: 40px;
    }

    &.oval--3 {
        top: 838px;
        left: -100px;
    }
}
