.p-skeleton {
    position: relative;
    background: $skeletonBg;
    border-radius: $borderRadius;
    overflow: hidden;

    div {
        position: relative;
        height: 100%;
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            width: inherit;
            height: inherit;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            transform: translate(-100%);
            animation: p-skeleton-animation 1.2s infinite;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), $skeletonAnimationBg, rgba(255, 255, 255, 0));
        }
    }

    &__attribute {
        display: flex;
        gap: 10px;
    }

    &__buttons {
        display: flex;
        gap: 10px;
    }

}

@keyframes p-skeleton-animation {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}
