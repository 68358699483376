@import "assets/scss/settings/fonts";
@import "assets/scss/settings/colors";
@import "assets/scss/settings/body";
@import "assets/scss/settings/headings";
@import "assets/scss/settings/wrapper";

// From here new imports
@import "assets/scss/elements/index";
@import "assets/scss/general/index";

input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
}
.car-info-card {
    border: 1px solid $color-border;
    padding: 9px;
    background: white;

    &__image {
        max-width: 100%;
        max-height: 256px;
    }

    &__title {
        @extend h3;
        margin: 16px 0px;
    }

    &__image__container {
        display: flex;
        align-items: center;
    }

    &__attribute {
        display: flex;
        justify-content: left;
        align-items: center;
        color: $color-black;
        margin: 4px 0 0 0;
        line-height: unset;
        font-size: 15px;
        font-family: $font-lexend;
        height: 33px;

        &__paymentmethod {
            font-size: 12px;
        }

        &__icon {
            margin-left: -6px;
            padding-top: 3px;
            margin-right: 10px;

            .pi-euro {
                width: 24px;

                &:before {
                    color: #d8d8d8;
                }
            }

            .pi-check {
                width: 24px;
                color: #d8d8d8;

                &:before {
                    font-size: 13px;
                    color: #d8d8d8;
                    font-weight: bolder;
                }
            }

        }

        &__note {
            color: darken($color-border, 40%);
            font-size: 15px;
        }
    }

    &__cta {
        margin-top: 16px;
    }
}
.loading {
    position: absolute;
    top: 50vh;
    left: 50vw;
    margin-left: -25px;
    z-index: 10000;
}
.cards-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 91px;
    margin-bottom: 190px;

    @media (max-width: 766px) {
        margin-top: 20px;
        grid-template-columns: unset;
        grid-template-rows: auto auto;
    }
}

.sidebar-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    background: #000000;
    transition: opacity .3s ease;
    will-change: opacity;
    pointer-events: none;

    &.visible {
        opacity: .2;
        pointer-events: auto;
    }
}

[hidden] {
    display: none !important;
}
