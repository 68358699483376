// Whitelabel colors
$color-primary: #02d830;
$color-secondary: #048ef9;
$color-danger: #EF4444;
$color-warning: #d77d23;
$color-danger-shade: #d02c2c;

// Color palette
$color-white: #ffffff;
$color-light: #e7e7e7;
$color-light-gray: #F7F7F7;
$color-gray: #EEEEEE;
$color-gray-light: #b5b5b5;
$color-gray-medium: #6c757d;
$color-black: #1D1C2D;

// Color Abstracts
$color-border: #d8d8d8;
$color-border-active: #60606c;

$box-shadow: 0 0 16px 0 rgba(29, 29, 27, 0.08);

:root {
    --active-link: #{$color-black};
    --accent-color: #{$color-secondary};
    --color-primary: #{$color-primary};
    --color-secondary: #{$color-secondary};
    --color-warning: #{$color-warning};
    --color-danger: #{$color-danger};
    --color-danger-shade: #{$color-danger-shade};

    --color-white: #{$color-white};
    --color-light: #{$color-light};
    --color-light-gray: #{$color-light-gray};
    --color-gray: #{$color-gray};
    --color-gray-medium: #{$color-gray-medium};
    --color-gray-light: #{$color-gray-light};
    --color-black: #{$color-black};

    --color-border: #{$color-border};
    --color-border-active: #{$color-border-active};

    --box-shadow: #{$box-shadow};
}
