.p-dialog {
    border-radius: $borderRadius;
    box-shadow: $overlayContainerShadow;
    border: $overlayContentBorder;

    .p-dialog-header {
        border-bottom: $dialogHeaderBorder;
        background: $dialogHeaderBg;
        //color: $dialogHeaderTextColor;
        padding: $dialogHeaderPadding;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-dialog-title {
            font-weight: $dialogHeaderFontWeight;
            font-size: $dialogHeaderFontSize;
        }

        .p-dialog-header-icon {
            @include action-icon();
            margin-right: $inlineSpacing;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .p-dialog-content {
        background: $overlayContentBg;
        color: $panelContentTextColor;
        padding: $dialogContentPadding;
    }

    .p-dialog-footer {
        border-top:  $dialogFooterBorder;
        background: $overlayContentBg;
        color: $panelFooterTextColor;
        padding: $dialogFooterPadding;
        text-align: right;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;

        button {
            margin: 0 $inlineSpacing 0 0;
            width: auto;
        }
    }

    &.p-confirm-dialog {
        .p-confirm-dialog-icon {
            font-size: $primeIconFontSize * 2;
        }
    }
}

.p-dialog-mask.p-component-overlay {
    background-color: $maskBg;
}

p-dialog.loading-dialog-cars .p-dialog {
    width: 90%;
    height: auto;
    overflow-y: unset;

    svg path {
        stroke: var(--color-primary);
    }

    @media screen and (min-width: 960px) {
        width: 35vw;
        height: auto;
        max-width: 500px;
        max-height: 500px;
    }
}
