h1 {
    font-family: $font-lexend;
    font-size: 58px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 74px;
    color: $color-black;
    margin: 0;
    padding: 0;
}

h2 {
    font-family: $font-lexend;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 50px;
    color: $color-black;
    margin: 0;
    padding: 0;
}

h3 {
    font-family: $font-lexend;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 32px;
    color: $color-black;
    margin: 0;
    padding: 0;
}

h4 {
    font-family: $font-lexend;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 32px;
    color: $color-black;
    margin: 0;
    padding: 0;
}

h5 {
    font-family: $font-lexend;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 28px;
    color: $color-black;
    margin: 0;
    padding: 0;
}
