.outer-container {
    max-width: 1133px;
    margin: 0 auto;
    display: block;

    @media (max-width: 1133px) {
        padding: 0 15px;
    }

    &.outer-container--small {
        max-width: 1017px;
    }

}

.full-height {
    height: 100%;
}
