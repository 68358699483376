.p-button {
    color: var(--color-primary-contrast);
    background: var(--color-primary);
    border: var(--color-primary);
    padding: 11px 14px;

    @media (max-width: 766px) {
        padding: 11px;
    }

    font-size: $fontSize;
    transition: $formElementTransition;
    border-radius: $borderRadius;

    &:enabled:hover {
        background: var(--color-secondary);
        border-color: var(--color-secondary);
        color: var(--color-secondary-contrast);
    }

    &:enabled:active {
        background: var(--color-secondary-shade);
        color: var(--color-secondary-contrast);
        border-color: var(--color-secondary-shade);
    }

    &.p-button-outlined {
        background-color: transparent;
        color: var(--color-primary);
        border: 1px solid var(--color-primary);

        &:enabled:hover {
            background: var(--color-primary);
            color: inherit;
            border: 1px solid var(--color-primary);
        }

        &:enabled:active {
            background: var(--color-primary-shade);
            color: inherit;
            border: 1px solid var(--color-primary-shade);
        }

        &.p-button-plain {
            color: var(--color-primary-contrast);
            border: 1px solid var(--color-primary);

            &:enabled:hover {
                background: var(--color-primary);
                color: inherit;
            }

            &:enabled:active {
                background: var(--color-primary-shade);
                color: inherit
            }
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: var(--color-secondary);
        border-color: transparent;
        border-radius: 0;
        padding: 2px;
        border-bottom: 1px solid transparent;

        &:enabled:hover {
            background-color: transparent;
            color: var(--color-secondary);
            border-bottom-color: var(--color-secondary-shade);
        }

        &:enabled:active {
            background-color: transparent;
            color: var(--color-secondary);
            border-bottom-color: var(--color-secondary-contrast);
        }

        &.p-button-plain {
            color: $plainButtonTextColor;

            &:enabled:hover {
                background: $plainButtonHoverBgColor;
                color: $plainButtonTextColor;
            }

            &:enabled:active {
                background: $plainButtonActiveBgColor;
                color: $plainButtonTextColor;
            }
        }
    }

    &-danger {
        &.p-button-text {
            color: var(--color-danger);

            &:enabled:hover {
                color: var(--color-danger-shade);
                border-bottom-color: var(--color-danger-shade);
            }
        }
    }

    &:focus {
        @include focused();
    }

    .p-button-icon-left {
         margin-right: $inlineSpacing;
    }

    .p-button-icon-right {
        margin-left: $inlineSpacing;
    }

    .p-button-icon-bottom {
        margin-top: $inlineSpacing;
    }

    .p-button-icon-top {
       margin-bottom: $inlineSpacing;
    }

    .p-badge {
        margin-left: $inlineSpacing;
        min-width: $fontSize;
        height: $fontSize;
        line-height: $fontSize;
        color: var(--color-primary);
        background-color: $buttonTextColor;
    }

    &.p-button-raised {
        box-shadow: $raisedButtonShadow;
    }

    &.p-button-rounded {
        border-radius: $roundedButtonBorderRadius;
    }

    &.p-button-icon-only {
        width: $buttonIconOnlyWidth;
        padding: $buttonIconOnlyPadding;

        .p-button-icon-left,
        .p-button-icon-right {
            margin: 0;
        }

        &.p-button-rounded {
            border-radius: 50%;
            height: $buttonIconOnlyWidth;
        }
    }

    &.p-button-sm {
        @include scaledFontSize($fontSize, $scaleSM);
        @include scaledPadding($buttonPadding, $scaleSM);

        .p-button-icon {
            @include scaledFontSize($primeIconFontSize, $scaleSM);
        }
    }

    &.p-button-lg {
        @include scaledFontSize($fontSize, $scaleLG);
        @include scaledPadding($buttonPadding, $scaleLG);

        .p-button-icon {
            @include scaledFontSize($primeIconFontSize, $scaleLG);
        }
    }

    &.p-button-loading-label-only {
        .p-button-label {
            margin-left: $inlineSpacing;
        }

        .p-button-loading-icon {
            margin-right: 0;
        }
    }

    &.p-datepicker-trigger {
        color: $color-black !important;
    }
}

.p-fluid {
    .p-button {
        width: 100%;
    }

    .p-button-icon-only {
        width: $buttonIconOnlyWidth;
    }

    .p-buttonset {
        display: flex;

        .p-button {
            flex: 1;
        }
    }
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: var(--color-secondary-contrast);
    background: var(--color-secondary);
    border-color: var(--color-secondary);

    &:enabled:hover {
        background: var(--color-primary);
        color: var(--color-primary-contrast);
        border-color: var(--color-primary);
    }

    &:enabled:focus {
        box-shadow: $secondaryButtonFocusShadow;
    }

    &:enabled:active {
        background: var(--color-primary-shade);
        color: var(--color-primary-contrast);
        border-color: var(--color-primary-shade);
    }

    &.p-button-outlined {
        background-color: transparent;
        color:  var(--color-secondary-shade);
        border-color: var(--color-secondary);

        &:enabled:hover {
            background: var(--color-secondary);
            color: var(--color-secondary-contrast);
            border-color: var(--color-secondary);
        }

        &:enabled:active {
            background: var(--color-secondary-shade);
            color: var(--color-secondary-contrast);
            border-color: var(--color-secondary-shade);
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: var(--color-primary);
        border-color: transparent;
        border-radius: 0;
        padding: 2px;

        &:enabled:hover {
            background-color: transparent;
            color: var(--color-primary);
            border-bottom: 1px solid var(--color-primary-shade);
        }

        &:enabled:active {
            background-color: transparent;
            color: var(--color-primary);
            border-bottom: 1px solid var(--color-primary-contrast);
        }
    }
}

.p-paginator {
    .p-paginator-prev.p-paginator-element,
    .p-paginator-next.p-paginator-element {
        @extend .p-button;

        .p-paginator-icon {
            color: var(--color-primary-contrast);
        }
    }
}
