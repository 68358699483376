.p-selectbutton {
    height: 44px;
    font-size: 14px;
    font-family: $font-lexend;
    font-weight: 400;

    .p-element {
        padding: 12px;
        height: 44px;

        &.p-highlight {
            background: var(--color-primary) !important;
            border-color: var(--color-primary) !important;
            color: var(--color-primary-contrast) !important;
        }

        span {
            font-size: 14px;
            font-family: $font-lexend;
            font-weight: 400;
        }
    }
}

button, .p-button-label {
    font-family: $font-lexend;
    font-weight: 500;
}

.p-button {
    text-decoration: none;

    &--full-width {
        width: 100%;
    }

    &-small {
        height: 37px;
    }

    &-white {
        background: $color-white;
        color: $color-black;
        border: 1px solid $color-white;
    }
}

a.p-button:active:not(.p-button-white) {
    background-color: var(--color-secondary-shade);
}

.rules-btn {
    color: var(--color-primary);
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}

a.link {
    color: var(--color-primary);

    &:active {
        color: var(--color-primary-shade);
    }
}

.link-unstyled {
    color: inherit;
    text-decoration: none;
}
