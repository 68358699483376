@use "sass:meta";

$font-lexend: 'Lexend', sans-serif;

:root {
    --font-lexend: #{meta.inspect($font-lexend)};
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.pi-euro {
    &::before {
        color: var(--color-primary);
        font-weight: 900;
    }
}

.price {
    &-discount-badge {
        display: inline-block;
        margin-left: .25rem;
        padding: 2px 5px;
        background-color: var(--color-primary);
        color: var(--color-white);
        border-radius: 10px;
        font-weight: 900;
        font-size: .8rem;
    }

    &-original {
        color: var(--color-gray-medium);
        text-decoration: line-through;
    }
}

.usp-block {
    h4 {
        display: inline-block;
        color: var(--color-white);
        margin: 0 8px;
    }
}
