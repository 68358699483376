//General
$primaryColor: $color-primary;
$focusOutlineColor: none;

//Button
$buttonPadding: 11px 40px;
$secondaryButtonBg: $color-secondary;
$tabviewHeaderActiveBorderColor: $color-secondary;
$tabviewHeaderTextActiveColor: $color-secondary;
$tabviewContentPadding: 1rem 0 0 0;
$focusShadow: none;

//Input
$inputPadding: 0.5rem 1rem;


$accordionHeaderBorder: 1px solid $color-border;
$accordionHeaderPadding: 1.5rem 0;
$accordionContentBorder: 0;


.p-accordion-header-link {
  border-radius: 0 !important;
  border-left: none !important;
  border-right: none !important;
  background: $color-white !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.p-accordion-header-text {
  font-size: 15px;
}
