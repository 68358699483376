body {
    padding: 0;
    margin: 0;
}

p, input, textarea, label {
    font-family: $font-lexend;
    font-size: 15px;
    font-weight: 400;
    color: $color-black;
    letter-spacing: 0px;
}

p {
    color: transparentize($color-black, 0.3);
    line-height: 32px;
}

.body {
    color: $color-black;
    font-weight: 500;
}

.sub-header {
    color: $color-black;
    font-weight: 600;
}
