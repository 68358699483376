.product-rule {
    height: 100%;

    &__icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    &__card {
        &, > div {
            display: block;
            height: 100%;
        }

        strong {
            color: var(--color-secondary);
            word-break: break-word;
        }
    }

    &__text {
        line-height: 1.5rem;

        &:not(&--expanded) {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;

            + .read-more + .read-less {
                display: none;
            }
        }

        &--expanded {
            + .read-more {
                display: none;
            }
        }
    }
}
